<div class="sp-content-wrapper">
    <div class="sp-content-header">
        <h1>Vulnerabilities</h1>
    </div>
    <div class="sp-content">
        <div class="sp-filterbox-wrapper">
            <div class="sp-filterbox">
                <div class="sp-filters">
                    <v-select
                        :options="product_list"
                        label="nameversion"
                        placeholder="Select product"
                        v-model="product"
                    ></v-select>
                    <v-select
                        :options="cluster_list"
                        :disabled="cluster_list.length == 0"
                        label="version"
                        placeholder="Select cluster"
                        v-model="cluster"
                    ></v-select>
                    <v-select
                        :options="component_list"
                        label="nameversion"
                        placeholder="Select component"
                        v-model="component"
                    ></v-select>
                    <v-select
                        :options="image_list"
                        label="name"
                        placeholder="Select artifact"
                        v-on:change="reloadCVEList()"
                        v-model="image"
                    ></v-select>
                    <v-select
                        :options="severity_list"
                        :searchable=false
                        :multiple=true
                        label="name"
                        placeholder="Select severity"
                        v-model="severities"
                    ></v-select>
                    <v-select
                        :options="exploitable_list"
                        :searchable=false
                        :multiple=false
                        label="name"
                        placeholder="Select exploitable"
                        v-model="exploitable"
                    ></v-select>
                    <v-select
                        :options="in_use_list"
                        :searchable=false
                        :multiple=false
                        label="name"
                        placeholder="Select in_use"
                        v-model="in_use"
                    ></v-select>
                    <v-select
                        :options="package_types"
                        :multiple=false
                        label="name"
                        placeholder="Select package type"
                        v-model="package_type"
                    ></v-select>
                    <v-select
                        :options="scanners"
                        :searchable=false
                        :multiple=false
                        placeholder="Select scanner"
                        v-model="scanner"
                        v-show="scanners.length > 1"
                    ></v-select>
                    <input
                        placeholder="CVE name"
                        v-model="cve_name_filter"
                        type="text"
                    >
                </div>
            </div>
            <div class="sp-filterbox-info">
                <span class="sp-filterbox-info-icon">
                    <span class="material-symbols-outlined">privacy_tip</span>
                </span>
                <div class="sp-filterbox-info-content">
                    <span class="sp-filterbox-info-number">CVEs: {{cveCount}}</span>
                    <span class="sp-filterbox-info-number">Components: {{componentCount}}</span>
                    <span class="sp-filterbox-info-number">Artifacts: {{imageCount}}</span>
                </div>
            </div>
        </div>
        <div class="cvetable">
            <div class="cvetable-row cvetable-row-header">
                <div class="cvetable-col expand-item-col clickable" @click="toggleAllItemVisibility(cve_list)">
                    <span class="material-symbols-outlined" v-if="isAllItemsExpanded(cve_list)">
                        collapse_all
                    </span>
                    <span class="material-symbols-outlined" v-else>
                        expand_all
                    </span>
                </div>

                    <div class="cvetable-col " @click="toggleAllItemVisibility(cve_list)">CVE</div>
                    <div class="cvetable-col " @click="toggleAllItemVisibility(cve_list)">Product</div>
                    <div class="cvetable-col " @click="toggleAllItemVisibility(cve_list)">Component</div>
                    <div class="cvetable-col " @click="toggleAllItemVisibility(cve_list)">Artifact</div>
                    <div class="cvetable-col " @click="toggleAllItemVisibility(cve_list)">Targeted in</div>
                    <div class="cvetable-col " @click="toggleAllItemVisibility(cve_list)">Scan date</div>
                    <div class="cvetable-col " @click="toggleAllItemVisibility(cve_list)">Severity</div>
                    <div class="cvetable-col expanded-menu"><div class="clickable" @click="saveDataToCSV"><span class="material-symbols-outlined">
                        save
                        </span></div>
                    </div>
            </div>
            <div v-for="(item, index) in cve_list" class="cve-details-item" :key="item.id">
                <div class="cvetable-row" :class="'row-' + severityClass(item.severity)">
                    <div class="cvetable-row-item ">
                        <div class="cvetable-col expand-item-col clickable" @click="toggleItemVisibility(index)">
                            <span class="material-symbols-outlined" v-if="isElementVisible(index)">
                                stat_2
                            </span>
                            <span class="material-symbols-outlined" v-else>
                                stat_minus_2
                            </span>
                        </div>
                        <div class="cvetable-col"><span class="clickable" @click="getCVEDetail(item.name,{ component: item.component, artifact: item.artifact_name, artifact_id: item.artifact_id})">{{item.name}}</span></div>
                        <div class="cvetable-сol"  @click="toggleItemVisibility(index)">
                           <div v-if="reasonByComponent(item.component_id) != ''">
                                <a :href=reasonByComponent(item.component_id) target="_blank"> {{productByComponent(item.component_id)}} </a>
                           </div>
                           <div v-else>
                                {{productByComponent(item.component_id)}}
                           </div>
                        </div>
                        <div class="cvetable-col "  @click="toggleItemVisibility(index)">{{item.component}}</div>
                        <div class="cvetable-col "  @click="toggleItemVisibility(index)">{{item.artifact_name}}</div>
                        <div class="cvetable-col "  @click="toggleItemVisibility(index)">{{item.fix_version_prd}}</div>
                        <div class="cvetable-col "  @click="toggleItemVisibility(index)">{{ getScanDate(item.scan_date) }}</div>
                        <div style="width: 200%;" class="col-text-left;"  @click="toggleItemVisibility(index)">
                            <span class="sp-severity-label" :class="'sp-severity-label-' + severityClass(item.severity)">
                                {{item.severity === '' ? 'N/A' : item.severity}}
                            </span>
                            &#8203;
                            <span v-if="item.in_use === 'true'" class="sp-severity-label" :class="'sp-severity-label-' + severityClass('medium')">
                                In Use
                            </span>
                        </div>
                        <div class="cvetable-col "></div>

                    </div>
                    <div class="cvetable-row-item-comment"  @click="toggleItemVisibility(index)">
                        <div class="cvetable-comment" v-show="item.package_path !== null"><i>Path:</i> <b>{{item.package_path}}</b></div>
                        <!-- <div class="cvetable-comment"><i>Scanner:</i> <b>{{item.scanner}}</b></div> -->
                        <div class="cvetable-comment" v-show="item.comment">
                            <i>Comment:</i> <b>{{item.comment}}</b>
                        </div>
                        <div class="cvetable-comment" v-show="item.jira_task !== null">
                            <i>Jira:</i> <b>
                                <a :href="generateJiraLink(item.jira_task)" target="_blank">{{ getJiraTaskName(item.jira_task) }}</a>
                            </b>
                        </div>
                        <div class="cvetable-comment" v-show="getJiraTaskAffects(item.jira_task) !== null">
                            <i>Affects:</i> <b>{{ getJiraTaskAffects(item.jira_task) }}</b>
                        </div>
                        <div class="cvetable-comment" v-show="getJiraTaskFixVer(item.jira_task) !== null">
                            <i>Fixed in:</i> <b>{{ getJiraTaskFixVer(item.jira_task) }}</b>
                        </div>
                    </div>

                    <div class="cvetable-row-item-comment"  v-show="isElementVisible(index)" @click="toggleItemVisibility(index)">
                        <div class="cvetable-comment"><span class="tooltiptext"> ArtifactId: <b>{{item.artifact_id}}</b> </span></div>
                        <div class="cvetable-comment"><i>Package:</i> <b>{{item.package}}</b></div>
                        <div class="cvetable-comment"><i>Type:</i> <b>{{item.package_type}}</b></div>
                        <div class="cvetable-comment" v-show="item.package_fixversion != ''">
                            <i>Fix version:</i> <b>{{item.package_fixversion}}</b>
                        </div>
                        <div class="cvetable-comment" v-show="item.exploitable !== null">
                            <i>Exploitable:</i> <b>{{item.exploitable ? 'Yes' : 'No'}}</b>
                        </div>
                        <div class="cvetable-comment"><i>SysdigPortal:</i> <a :href="generateUrl(item.artifact_name)" target="_blank" class="url-link">Sysdig Report</a></div>
                        <div v-if=item.in_use class="cvetable-comment"><i>InUse:</i> <b>{{item.in_use}}</b></div>
                    </div>

                    </div>
            </div>
        </div>
        <div class="sp-paginator">
            <paginate
                v-model="pageNumber"
                :page-count="pageCount"
                :prev-text="'Prev'"
                :next-text="'Next'"
                :click-handler="reloadCVEList"
                :container-class="'sp-pagination'"
            />
            <div style="min-width: 20px;"></div>
            <div class="sp-pagesize-wrap">
              <div class="sp-pagesize">{{ pageSize.name }}</div>
              <div class="sp-pagesize-list">
                <div class="sp-pagesize-item" v-for="item in pageSizes" :key="item.id" v-on:click="setPageSize(item)">{{ item.name }}</div>
              </div>
            </div>
        </div>
    </div>
</div>
<Teleport to="body">
    <CVEDetail
        v-if="showCVEDetail"
        :cve-info-init="cveInfo"
        :selectedCveInfo="selectedCveInfo"
        @close="showCVEDetail = false"
    />
</Teleport>
